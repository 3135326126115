<template>
    <div>
      <transition-group name="list-complete" tag="div">
        <div class="list" v-for="(item, index) in list" :key="item.id" @click="workDetail(index)">
          <div class="list-content">
            <div class="info">
              <div class="flex">
                <el-tooltip :content="item.name">
                  <div class="name">{{item.name}}</div>
                </el-tooltip>
                <div class="bag-name" v-if="item.bagName !== ''">{{item.bagName}}</div>
              </div>
              <div class="type">{{item.type}}</div>
            </div>
            <div class="clazz">{{item.clazzName}}</div>
            <div class="num-column">
              <div class="mb-10">{{item.score}}/{{item.totalScore}}分</div>
              <div>{{item.count}}/{{item.totalNum}}完成</div>
            </div>
            <div class="type-column">
              <div class="theme-color" :class="['mb-10', item.status == '0' ? 'col-329cf3' : item.status == '1' ? 'col-1DC060' : 'col-999']">{{item.status == '0' ? '未开始' : item.status == '1' ? '进行中' : '已结束'}}</div>
              <div class="time">
                <span>{{item.showDateStart}}</span>
                <span class="text">至</span>
                <span>{{item.showDateEnd}}</span>
              </div>
            </div>
            <div class="btn-column">
              <div class="btn btn-1" @click.stop="exportExcel(item.id)">导</div>
              <div class="btn btn-2" @click.stop="setDate(index)">修</div>
              <div class="btn btn-3" @click.stop="delWork(index)">删</div>
            </div>
          </div>
        </div>
      </transition-group>

      <setDateDialog
        ref="setDateDialog"
        @refreshPage="refreshPage"></setDateDialog>
    </div>
</template>

<script>
import setDateDialog from '@/components/setDateDialog.vue'

export default {
  name: 'WorkList',
  props: ['list'],
  data () {
    return {
      dialogVisible: false,
      dialogDate: '',
      index: ''
    }
  },
  components: {
    setDateDialog
  },
  methods: {
    refreshPage () {
      this.$emit('refreshPage')
    },
    exportExcel (itemId) {
      this.$emit('exportExcel', itemId)
    },
    setDate (index) {
      const { list } = this
      this.$refs.setDateDialog.dialogVisibleChange(list[index])
    },
    delWork (index) {
      this.$emit('delWork', index)
    },
    workDetail (index) {
      const { list } = this
      const { id, bagId } = list[index]
      const { name } = list[index]
      const { clazzId } = list[index]
      const { passageType } = list[index]
      this.$router.push({
        name: 'workDetail',
        query: {
          itemId: id,
          name,
          clazzId,
          passageType,
          bagId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 0 28px;
    cursor: pointer;
    transition: .5s;
    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
    }
    .list-content {
      @include flex;
      height: 100px;
      border-bottom: 1px solid #F1F1F1;
      .info {
        flex-grow: 1;
        overflow: hidden;
        .flex {
          @include flex(flex-start);
          margin-bottom: 10px;
        }
        .name {
          font-size: 17px;
          font-weight: bold;
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bag-name {
          background-color: #EAF4FD;
          height: 24px;
          line-height: 24px;
          padding: 0 10px;
          border-radius: 12px;
          @include font(14px, #309AF2, center);
          white-space: nowrap;
        }
        .type {
          @include font(13px, #999);
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .clazz {
        flex-shrink: 0;
        width: 130px;
        font-size: 17px;
        text-align: center;
      }
      .num-column {
        flex-shrink: 0;
        width: 130px;
        font-size: 15px;
        text-align: center;
      }
      .type-column {
        flex-shrink: 0;
        width: 280px;
        font-size: 15px;
        text-align: center;
        .time {
          font-size: 14px;
          .text {
            margin: 0 14px;
          }
        }
      }
      .btn-column {
        flex-shrink: 0;
        @include flex;
        width: 136px;
        .btn {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #1DC060;
          border-radius: 4px;
          margin-right: 8px;
          @include font(15px, #1DC060, center);
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }

        .btn-1:hover{
          color: #FFFFFF;
          background-color: #1DC060;
        }

        .btn-2 {
          color: #309AF2;
          border-color: #309AF2;

          &:hover{
            color: #FFFFFF;
            background-color: #309AF2;
          }
        }
        .btn-3 {
          color: #FF3C30;
          border-color: #FF3C30;

          &:hover{
            color: #FFFFFF;
            background-color: #FF3C30;
          }
        }
      }
      .mb-10 {
        margin-bottom: 10px;
      }
    }
  }
</style>
