<template>
  <article v-loading="loading">
    <header class="header">
      <h1>检查作业</h1>
      <div class="column">
        <div class="left">
          <el-select class="mr-14" v-model="clazzId" @change="clazzChange">
            <el-option v-for="item in clazz" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="status" @change="statusChange">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="type" @change="typeChange">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
    </header>

    <section class="content" v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-immediate="false" infinite-scroll-distance="10">
      <WorkList
        :list="list"
        @exportExcel="exportExcel"
        @setDate="setDate"
        @delWork="delWork"
        @refreshPage="refreshPage"
        v-if="type === 1"></WorkList>
      <BagList
        :list="list"
        @exportExcel="exportExcel"
        @delWork="delWork"
        @bagWorkList="bagWorkList"
        @setWork="setWork"
        v-else
      ></BagList>
      <div class="nomore" v-show="disabled">没有更多了</div>
    </section>
    <EditWorkSet ref="EditWorkSet" @refreshPage="refreshPage2"></EditWorkSet>
  </article>
</template>

<script>
import WorkList from './components/WorkList.vue'
import BagList from '../helper/components/BagList.vue'
import EditWorkSet  from '@/components/EditWorkSet'

export default {
  name: 'checkWork',
  components: {
    WorkList,
    BagList,
    EditWorkSet
  },
  data () {
    return {
      loading: false,
      clazz: [],
      clazzId: '',
      statusList: [{
        'id': '',
        'name': '全部状态'
      },
      {
        'id': '0',
        'name': '未开始'
      },
      {
        'id': '1',
        'name': '进行中'
      },
      {
        'id': '2',
        'name': '已结束'
      }],
      status: '',
      typeList: [
        { id: 1, name: '按作业明细查看' },
        { id: 2, name: '按作业名称查看' }
      ],
      type: 2,
      list: [],
      pageIndex: 1,
      pageSize: 20,
      disabled: false
    }
  },
  created () {
    if (this.$route.query.clazzId){
      this.clazzId = this.$route.query.clazzId
    }
    this.getClass()
    this.findBagList()
  },
  methods: {
    refreshPage () {
      if (this.type === 1) {
        this.getHomeworkList()
      } else {
        this.findBagList()
      }
    },
    refreshPage2() {
      this.findBagList()
    },
    bagWorkList (index) {
      const { list } = this
      const { id } = list[index]
      const { name } = list[index]
      this.$router.push({
        name: 'bagWorkList',
        query: {
          id,
          name
        }
      })
    },
    setWork(item) {
      this.$refs.EditWorkSet.dialogVisibleChange(item)
    },
    exportExcel (itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = sessionStorage.getItem('schoolProvinceId')
      const section = sessionStorage.getItem('schoolSection')
      console.log(provinceId, section)
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : '')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const convertScore = checked ? 1 : 0
          checked = false
          switch (that.type) {
            case 1:
              that.$axios({
                method: 'post',
                url: that.$urls.exportItemScope,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {
                  itemId: itemId,
                  convertScore
                }
              }).then((response) => {
                if (response.state === '11') {
                  that.$message.success('下载任务已提交，请前往下载中心查看进度')
                }
              }).catch(() => {
              })
              break
            case 2:
              that.$axios({
                method: 'post',
                url: that.$urls.exportBagScope,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {
                  bagId: itemId,
                  convertScore
                }
              }).then((response) => {
                if (response.state === '11') {
                  that.$message.success('下载任务已提交，请前往下载中心查看进度')
                }
              }).catch(() => {
              })
              break

          }
        })
        .catch((err) => {
          checked = false
          throw err
        })
    },
    setDate (index, date) {
      const { list } = this
      const { id } = list[index]
      date = this.common.binarySystem(date)
      date = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(date))
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkUpdateItem,
        data: {
          itemId: id,
          dateEnd: date
        }
      })
        .then((response) => {
          if (response.state === '11') {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            const time = this.common.formatDate('MM-dd hh:mm', new Date(date))
            this.$set(this.list[index], 'showDateEnd', time)
          }
        })
    },
    delWork (index) {
      const that = this
      this.$confirm('删除后学生将无法收到该作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.confirmDel(index)
        })
        .catch((err) => {
          throw err
        })
    },
    confirmDel (index) {
      const { list } = this
      const { id } = list[index]
      const { type } = this
      let url
      const dataParam = {}
      if (type === 1) {
        url = this.$urls.homeworkDelUrl
        dataParam.id = id
      } else {
        url = this.$urls.deleteHomeBag
        dataParam.bagId = id
      }
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      })
        .then((response) => {
          if (response.state === '11') {
            this.list.splice(index, 1)
          }
        })
    },
    clazzChange () {
      this.pageIndex = 1
      if (this.type === 1) {
        this.getHomeworkList()
      } else {
        this.findBagList()
      }
    },
    statusChange () {
      this.pageIndex = 1
      if (this.type === 1) {
        this.getHomeworkList()
      } else {
        this.findBagList()
      }
    },
    typeChange () {
      this.pageIndex = 1
      if (this.type === 1) {
        this.getHomeworkList()
      } else {
        this.findBagList()
      }
    },
    // 获取班级
    getClass () {
      this.$axios({
        method: 'post',
        url: this.$urls.gradeClassList
      })
        .then((response) => {
          if (response.state === '11') {
            this.clazz = [{id: '', name: '全部班级'}, ...response.data]
          }
        })
    },
    // 作业包列表
    findBagList () {
      this.loading = true
      const { pageIndex } = this
      const { pageSize } = this
      const dataParam = {}
      dataParam.pageIndex = pageIndex
      dataParam.pageSize = pageSize
      const { status } = this
      if (status !== '') dataParam.status = status
      const { clazzId } = this
      if (clazzId !== '') dataParam.clazzId = clazzId
      this.$axios({
        method: 'post',
        url: this.$urls.findBagList,
        data: dataParam
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            this.disabled = data.length < pageSize
            for (let i = 0, len = data.length; i < len; i += 1) {
              let { dateStart } = data[i]
              const arr = dateStart.split('T')
              data[i].dateStart = arr[0]
            }
            const { list } = this
            this.list = pageIndex === 1 ? data : [...list, ...data]
            this.$store.commit('includeAdd', 'checkWork')
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 子作业列表
    getHomeworkList () {
      this.loading = true
      const { pageIndex } = this
      const { pageSize } = this
      const dataParam = {}
      dataParam.page = pageIndex
      dataParam.pageSize = pageSize
      const { status } = this
      if (status !== '') dataParam.status = status
      const { clazzId } = this
      if (clazzId !== '') dataParam.clazzId = clazzId
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkList,
        headers: { 'Content-Type': 'application/json' },
        data: dataParam
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const data = response.data.pageContents
            for (let i = 0, len = data.length; i < len; i += 1) {
              const { dateStart } = data[i]
              data[i].showDateStart = this.common.formatDate('MM-dd hh:mm', new Date(dateStart))
              const { dateEnd } = data[i]
              data[i].showDateEnd = this.common.formatDate('MM-dd hh:mm', new Date(dateEnd))
            }
            this.disabled = data.length < pageSize
            const { list } = this
            this.list = pageIndex === 1 ? data : [...list, ...data]
            this.$store.commit('includeAdd', 'checkWork')
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    load () {
      if(this.pageIndex > 1 && this.loading){
        return
      }
      this.pageIndex += 1
      if (this.type === 1) {
        this.getHomeworkList()
      } else {
        this.findBagList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    @include flex(space-between, flex-start);
    flex-direction: column;
    background-color: #fff;
    height: 126px;
    padding: 28px 28px 10px;
    margin-bottom: 10px;
    .column {
      @include flex(space-between);
      width: 100%;
      .mr-14 {
        margin-right: 14px;
      }
    }
  }
  .content {
    background-color: #fff;
    height: calc(100vh - 220px);
    overflow: auto;
    padding: 10px 28px;
  }
</style>
